import React from "react"
import { Link } from "gatsby"
import { graphql } from "gatsby"


import SEO from "components/seo"

export default ({data}) => (
  <>
    <SEO title={data.siteFields.category.name} description={data.siteFields.category.name}/>
    
    <h1 dangerouslySetInnerHTML={{ __html: data.siteFields.category.name }} />

    {data.siteFields.category.posts.edges.map(({ node }) => (
      <div key={node.slug}>
        <Link to={`/${node.slug}`}>
        <div dangerouslySetInnerHTML={{ __html: node.title }} />
        </Link>
        <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
      </div>
    ))}
  </>
)



export const query = graphql`
  query($databaseId: ID!) {
    siteFields {
      category(id: $databaseId, idType: DATABASE_ID) {
        name
        posts {
          edges {
            node {
                slug
                databaseId
                title
                excerpt
            }
          }
        } 
      }
    }
  }
`